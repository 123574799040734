import React from "react"
import styled from "styled-components"
import axios from "axios"
import { navigate, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import {
  SectionPage,
  Container,
  SectionTitle,
  LeftTriangle,
  RightTriangle,
  BreadCrumb,
  Checkbox,
  HelpText,
  SectionPageTitle,
  SectionDescription,
  ExploreMore,
} from "../components/Section"
import {
  ControlLabel,
  CustomValidationMessage,
  RequiredTriangle,
  FormRow,
  FormTwoCol,
  FormCol,
  Field,
} from "../components/FormStyle"
import PrimaryButton from "../components/Button/PrimaryButton"
import ContactUsCarousel from "../components/ContactUsCarousel"
import AddressIcon from "../components/Icons/AddressIcon"
import EmailIcon from "../components/Icons/EmailIcon"
import CallPhone from "../components/Icons/CallPhone"

const SectionContactForm = styled.div`
  position:relative;
  z-index:1;
`
const AddressGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  height: 220px;
  @media (max-width: 1024px) {
    display: none;
  }
`
const GridItem = styled.div`
  padding: 0 10%;
  border: 1px solid #98dfff;
  height: 180px;
  display: flex;
  align-items: center;
  & :hover {
    box-shadow: 0px 15px 45px rgba(152, 223, 255, 0.2);
    border-radius: 5px;
    height: 220px;
  }
`
const Icon = styled.div`
  width: 20%;
  display: inline-block;
  vertical-align: middle;
  & svg {
    width: 90%;
  }
  @media (max-width: 1100px) {
    width: 15%;
  }
`
const TextWrap = styled.div`
  width: 80%;
  display: inline-block;
  vertical-align: middle;
  & strong {
    display: block;
    font-size: 24px;
    line-height: 29px;
    color: #062c44;
    margin-bottom: 5px;
    font-family: Chakra Petch, sans-serif;
    font-weight: 700;
  }
  & p {
    text-align: left;
    font-size: 16px;
    line-height: 22px;

    a {
      color: #062c44;
    }

    a:hover {
      color: #ea5f2c;
      text-decoration: underline !important;
    }
  }
  @media (max-width: 1100px) {
    width: 80%;
    margin-left: 5%;
  }
`

const CustomRequired = styled(CustomValidationMessage)`
width: fit-content;
`

class ContactUsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      state: "",
      city: "",
      comment: "",
      agreeViaSms: true,
      fNameRequire: false,
      lNameRequire: false,
      emailRequire: false,
      phoneRequire: false,
      stateRequire: false,
      cityRequire: false,
      commentRequire: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    const { id, value } = event.target

    if (id !== "agreeViaSms") {
      this.setState({
        [id]: value,
      })
    } else {
      this.setState({
        [id]: event.target.checked,
      })
    }
    if (id === "first_name") {
      this.setState({
        fNameRequire: false,
      })
    }
    if (id === "last_name") {
      this.setState({
        lNameRequire: false,
      })
    }
    if (id === "email") {
      this.setState({
        emailRequire: false,
      })
    }
    if (id === "phone_no") {
      this.setState({
        phoneRequire: false,
      })
    }
    if (id === "state") {
      this.setState({
        stateRequire: false,
      })
    }
    if (id === "city") {
      this.setState({
        cityRequire: false,
      })
    }
    if (id === "comment") {
      this.setState({
        commentRequire: false,
      })
    }
    if (id === "agreeViaSms") {
      this.setState({
        agreeViaSmsRequire: false,
      })
    }
  }
  handleSubmit = event => {
    const { location } = this.props
    event.preventDefault()
    if (this.state.first_name === "") {
      this.setState({
        fNameRequire: true,
      })
    } else if (this.state.last_name === "") {
      this.setState({
        lNameRequire: true,
      })
    } else if (this.state.email === "") {
      this.setState({
        emailRequire: true,
      })
    } else if (this.state.phone_no === "") {
      this.setState({
        phoneRequire: true,
      })
    } else if (this.state.state === "") {
      this.setState({
        stateRequire: true,
      })
    } else if (this.state.city === "") {
      this.setState({
        cityRequire: true,
      })
    } else if (this.state.comment === "") {
      this.setState({
        commentRequire: true,
      })
    } else {
      const data = {
        source: location.origin,
        form: "Contact Form",
        page: location.pathname,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_no: this.state.phone_no,
        state: this.state.state_name,
        city: this.state.city,
        comment:
          this.state.comment + ", SMS consent : " + this.state.agreeViaSms,
        agreeViaSms: this.state.agreeViaSms,
      }
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization:
            "Bearer " +
            "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
        },
      })
        .then(res => {
          this.setState({
            first_name: "",
            last_name: "",
            email: "",
            phone_no: "",
            zipcode: "",
            state_name: "",
            city: "",
            comment: "",
            agreeViaSms: false,
          })
          navigate("/thank-you-contact-us/")
        })
        .catch(error => {
          alert(error)
        })
    }
  }
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Contact Us" description="Contact Us" />
        <SectionPage
          ept="160px"
          epb="60px"
          xpt="140px"
          xpb="40px"
          pt="100px"
          pb="30px"
          bg="#fff"
        >
          <Container>
            <BreadCrumb className="static"><Link to="/">Home</Link> / <span>Contact Us </span></BreadCrumb>
            <SectionPageTitle textAlign="center">Contact Us</SectionPageTitle>
            <SectionDescription textAlign="center" maxWidth="920px">
              <p>We want to hear from you. Whether you are buying, looking to buy or simply have a question for one of our building experts please give us a call or email us. You can also find us on social media!</p>
            </SectionDescription>
            <AddressGrid>
              <GridItem>
                <Icon><AddressIcon /></Icon>
                <TextWrap>
                  <strong>Address</strong>
                  <p><a href="https://maps.app.goo.gl/ypmUL8Sg1Ta7ST3b7" target="_blank" rel="noreferrer" aria-ControlLabel="maps">3752 W US Hwy 90, Lake City, Fl 32055</a></p>
                </TextWrap>
              </GridItem>
              <GridItem>
                <Icon><EmailIcon /></Icon>
                <TextWrap>
                  <strong>Email Address</strong>
                  <p><a href="mailto:info@probuiltsteel.com">info@probuiltsteel.com</a></p>
                </TextWrap>
              </GridItem>
              <GridItem>
                <Icon><CallPhone /></Icon>
                <TextWrap>
                  <strong>Contact Number</strong>
                  <p><a href="tel:8777541818">1-877-754-1818</a></p>
                </TextWrap>
              </GridItem>
            </AddressGrid>
            <ContactUsCarousel />
          </Container>
        </SectionPage>
        <SectionPage
          ept="80px"
          epb="0"
          xpt="40px"
          xpb="0"
          pt="30px"
          pb="0"
          bg="#F4FBFF"
        >
          <Container maxWidth="920px">
            <SectionTitle>Get In Touch</SectionTitle>
            <SectionDescription textAlign="center">
              <p>Our professional staff is waiting to help solve your storage challenges. We’re happy to answer any question, so please get in touch by phone or the form below.</p>
            </SectionDescription>
            <SectionContactForm>
              <form id="contact-form" onSubmit={this.handleSubmit}>
                <FormRow>
                  <FormTwoCol>
                    <Field>
                      <ControlLabel>First Name</ControlLabel>
                      <input
                        className="form-control bg-transparent"
                        id="first_name"
                        placeholder="Please Enter Your First Name"
                        onChange={this.handleChange}
                        pattern="[A-Za-z\s]+$"
                      />
                      <CustomRequired
                        display={this.state.fNameRequire ? "block" : "none"}
                      >
                        <RequiredTriangle />
                        <span>Please input this field</span>
                      </CustomRequired>
                    </Field>
                  </FormTwoCol>
                  <FormTwoCol>
                    <Field>
                      <ControlLabel>Last Name</ControlLabel>
                      <input
                        className="form-control bg-transparent"
                        id="last_name"
                        placeholder="Please Enter Your Last Name"
                        onChange={this.handleChange}
                        pattern="[A-Za-z\s]+$"
                      />
                      <CustomRequired
                        display={this.state.lNameRequire ? "block" : "none"}
                      >
                        <RequiredTriangle />
                        <span>Please input this field</span>
                      </CustomRequired>
                    </Field>
                  </FormTwoCol>
                  <FormTwoCol>
                    <Field>
                      <ControlLabel>Email Address</ControlLabel>
                      <input
                        className="form-control bg-transparent"
                        id="email"
                        placeholder="Please Enter Your Email Address"
                        onChange={this.handleChange}
                        pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                      />
                      <CustomRequired display={this.state.emailRequire ? "block" : "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
                    </Field>
                  </FormTwoCol>
                  <FormTwoCol>
                    <Field>
                      <ControlLabel>Phone Number</ControlLabel>
                      <input className="form-control bg-transparent" id="phone_no" placeholder="Tell us your Personal Number" onChange={this.handleChange} pattern="[0-9]{10}$" />
                      <CustomRequired display={this.state.phoneRequire ? "block" : "none"} ><RequiredTriangle /><span>Please input this field</span></CustomRequired>
                    </Field>
                  </FormTwoCol>
                  <FormTwoCol>
                    <Field>
                      <ControlLabel>State</ControlLabel>
                      <input
                        className="form-control bg-transparent"
                        id="state"
                        placeholder="Please Enter Your State"
                        onChange={this.handleChange}
                        pattern="^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$"
                      />
                      <CustomRequired display={this.state.stateRequire ? "block" : "none"}><RequiredTriangle /> <span>Please input this field</span></CustomRequired>
                    </Field>
                  </FormTwoCol>
                  <FormTwoCol>
                    <Field>
                      <ControlLabel>City</ControlLabel>
                      <input className="form-control bg-transparent" id="city" placeholder="Please Enter Your City" onChange={this.handleChange} pattern="^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$" />
                      <CustomRequired display={this.state.cityRequire ? "block" : "none"}><RequiredTriangle /> <span>Please input this field</span></CustomRequired>
                    </Field>
                  </FormTwoCol>
                  <FormCol>
                    <Field>
                      <ControlLabel>Comment</ControlLabel>
                      <textarea
                        className="form-control bg-transparent"
                        id="comment"
                        placeholder="Write your comment here…"
                        rows="2"
                        onChange={this.handleChange}
                      />
                      <CustomRequired display={this.state.commentRequire ? "block" : "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
                    </Field>
                  </FormCol>
                  <FormCol>
                    <Field>
                      <Checkbox>
                        <ControlLabel htmlFor="sms">
                          <input type="checkbox" name="sms" value="Yes" defaultChecked="checked" id="sms" onChange={this.handleChange}/>I agree that I will be contacted via SMS.</ControlLabel>
                      </Checkbox>
                      <HelpText>By clicking 'Submit,' you are agreeing to the Probuilt Steel Buildings Terms of Use and Privacy Policy. You are also consenting to receive phone calls and SMS messages from Probuilt Steel Buildings to provide updates and information regarding your business with Probuilt Steel Buildings. The frequency of messages may vary, and message and data rates may apply. If you wish to opt out of further messaging, reply STOP. For more information, reply HELP. Please refer to our Privacy Policy for details.</HelpText>
                    </Field>
                  </FormCol>
                </FormRow>
                <ExploreMore><button type="submit" form="contact-form" className="btn-lg"><PrimaryButton text="Submit" /></button></ExploreMore>
              </form>
            </SectionContactForm>
          </Container>
          <LeftTriangle />
          <RightTriangle />
        </SectionPage>
        <CallToAction />
      </Layout>
    )
  }
}

export default ContactUsPage
